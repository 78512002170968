import css from '@styled-system/css';
import styled from 'styled-components';

import { Box } from '@xceedsrl/jukebox';

export const ScrollSpy = Box;

export const TopAnchor = styled(Box)(
  css({
    display: 'block',
    position: 'relative',
    top: [-60, -72],
    visibility: 'hidden',
  })
);
