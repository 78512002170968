import { Bar } from 'models/Bar';

import hosts from '../hosts';
import request from '..';

export class BarController {
  static getInfo = async ({ id, slug, lang }) => {
    const bar = await request({
      path: `/v1/bars/${id || slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
    return new Bar(bar);
  };

  static getGallery = ({ id, slug, lang }) => {
    return request({
      path: `/v1/bars/${id || slug}/gallery`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getFollowers = ({ id, slug, lang }) => {
    return request({
      path: `/v1/bars/${id || slug}/followers`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getReviews = ({ id, slug, lang }) => {
    return request({
      path: `/v1/bars/${id || slug}/reviews`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getAll = async ({ lang, ...params }) => {
    const bars = await request({
      path: `/v1/bars`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
      headers: { 'Accept-Language': lang },
    });

    return bars.map(bar => new Bar(bar));
  };

  static getAllByCategory = async ({ lang, slug, ...params }) => {
    const bars = await request({
      path: `/v1/bars/categories/${slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
      headers: { 'Accept-Language': lang },
    });

    return bars.map(bar => new Bar(bar));
  };
}
