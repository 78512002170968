import { News } from 'models/News';

import hosts from '../hosts';
import request from '..';

function prepareIds(ids) {
  const { artists, venues, cities, countries, tag } = ids;

  return {
    artists: Array.isArray(artists) ? artists.join(',') : artists,
    venues: Array.isArray(venues) ? venues.join(',') : venues,
    cities: Array.isArray(cities) ? cities.join(',') : cities,
    countries: Array.isArray(countries) ? countries.join(',') : countries,
    tag: Array.isArray(tag) ? tag.join(',') : tag,
  };
}

export class NewsController {
  static getNews = async ({ entityIds, lang, ...params }) => {
    const query = prepareIds(entityIds);

    const news = await request({
      path: '/v1/news',
      baseUrl: hosts.NEWS_SERVICE,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        orderBy: 'date',
        ...query,
        ...params,
      },
    });

    return news.map(item => new News(item));
  };
}
