import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as Types from 'types';

import { ActionBar, composeRef, VenueCard, VenueCardInMap } from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';
import { BarController } from 'services/apiClient/interfaces/BarController';
import { useChannelState } from 'store/channel';

import Link from 'components/CustomLink';

import { LikeAction } from './LikeAction';

export const BarWrapper = forwardRef(function BarWrapper({ bar, likeDisabled, ...props }, ref) {
  const { id, name } = bar;

  const actionElement = useMemo(() => {
    return (
      <ActionBar gap={1}>
        {likeDisabled ? null : <LikeAction type="bars" id={id} name={name} />}
      </ActionBar>
    );
  }, [likeDisabled, id, name]);

  return <VenueCard ref={ref} actionElement={actionElement} {...bar} {...props} />;
});

export const BarSmallWrapper = forwardRef(function BarSmallWrapper({ bar, ...props }, outerRef) {
  const { lang, t } = useTranslation();
  const [ref, inView] = useInView({
    rootMargin: '400px',
  });

  const { data: barDetails, refetch } = useQuery(
    ['bar', { id: bar.id, lang }],
    () => BarController.getInfo({ id: bar.id, lang }),
    { enabled: false }
  );

  const setRefs = useCallback(composeRef(ref, outerRef), [ref]);

  useEffect(() => {
    if (inView && !barDetails) refetch();
  }, [inView, barDetails]);

  return (
    <VenueCardInMap
      ref={setRefs}
      name={bar.name}
      coverUrl={bar.coverUrl}
      type={!barDetails?.foodType.length ? t('common:bar') : t('common:foodAndDrinks')}
      priceLevel={barDetails?.priceLevel}
      tags={barDetails ? [...barDetails.facilities, ...barDetails.characteristics] : undefined}
      {...props}
    />
  );
});

export const LinkedWrapper = forwardRef(function LinkedBarWrapper(
  { card: Card, customChannel, openInNewTab, ...props },
  ref
) {
  const {
    bar: { city, slug, name },
  } = props;

  const channel = useChannelState();

  return (
    <Link
      href="/[geo]/bar/[...params]"
      as={`/${city.slug}/bar/${slug}`}
      channel={customChannel || channel}
      aria-label={name}
      openInNewTab={openInNewTab}
    >
      <Card ref={ref} {...props} />
    </Link>
  );
});

export const LinkedBar = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={BarWrapper} {...props} />
));

export const LinkedBarSmall = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={BarSmallWrapper} {...props} />
));

BarWrapper.propTypes = {
  bar: Types.Bar.isRequired,
  likeDisabled: PropTypes.bool,
};

BarWrapper.defaultProps = {
  likeDisabled: false,
};

BarSmallWrapper.propTypes = {
  bar: Types.Bar.isRequired,
};

LinkedWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  card: PropTypes.object.isRequired,
  openInNewTab: PropTypes.bool,
  ...BarWrapper.propTypes,
};

LinkedWrapper.defaultProps = {
  openInNewTab: false,
};

LinkedBar.propTypes = BarWrapper.propTypes;

LinkedBarSmall.propTypes = BarSmallWrapper.propTypes;
