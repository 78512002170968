import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Carousel,
  ChevronRight,
  Col,
  Container,
  Flex,
  Icon,
  Row,
  SecondaryText,
  SecondaryTitle,
  SkeletonEventCard,
  Text,
  useMediaQuery,
} from '@xceedsrl/jukebox';

import { useCategory } from 'hooks/useCategory';
import { useTranslation } from 'i18n';
import { useChannelState } from 'store/channel';

import Link from 'components/CustomLink';
import { LinkedEvent } from 'components/EventWrappers';

import { SeeAllButton, SkeletonHeader } from './components';

const MAX_EVENTS = 11;
const MIN_TO_SHOW = 2;

function shouldDisplay(inCityPage, numItems, category) {
  if (inCityPage && numItems) return true;

  if (!inCityPage && numItems && category === 'all-events') return true;
  if (!inCityPage && numItems && numItems >= MIN_TO_SHOW) return true;
  return false;
}

const EventsCategoryPreview = React.memo(function EventsCategoryPreview(props) {
  const { title, subtitle, categorySlug, initialData, geo, inCityPage, onLoad } = props;
  const { t, lang } = useTranslation();
  const isMobile = useMediaQuery('mobile');
  const channel = useChannelState();

  const {
    data: events,
    isLoading,
    isSuccess,
  } = useCategory({
    type: 'event',
    geo,
    slug: categorySlug,
    limit: MAX_EVENTS,
    initialData,
    onLoad,
    lang,
  });

  if (isSuccess && !shouldDisplay(inCityPage, events?.length, categorySlug)) return null;
  if (isLoading)
    return (
      <Container mb={5}>
        <Row>
          <Col width={[1, 1 / 3]}>
            <SkeletonHeader uniqueKey={`${categorySlug}-header`} />
          </Col>
        </Row>
        <Row>
          {Array(isMobile ? 1 : 3)
            .fill(SkeletonEventCard)
            .map((Skeleton, i) => (
              <Col width={[1, 1 / 3]} key={`${categorySlug}-${i}`}>
                <Skeleton uniqueKey={`${categorySlug}-${i}`} />
              </Col>
            ))}
        </Row>
      </Container>
    );
  return (
    <>
      <Box mb={2}>
        <Container>
          <Flex mb={2} alignItems="center">
            <Box mr={2}>
              <Link
                href="/[geo]/events/all/[...params]"
                as={`/${geo}/events/all/${categorySlug}`}
                channel={channel}
                aria-label={title}
                withFilters
              >
                <SecondaryTitle mb={1}>{title}</SecondaryTitle>
              </Link>
              <SecondaryText>{subtitle}</SecondaryText>
            </Box>
            <Flex flex={1} />
            <Link
              href="/[geo]/events/all/[...params]"
              as={`/${geo}/events/all/${categorySlug}`}
              channel={channel}
              aria-label="see all"
              withFilters
            >
              <Icon fontSize={[3, 3, 4]} color="nightgraph">
                <ChevronRight />
              </Icon>
              <Text style={{ display: 'none' }}>{title}</Text>
            </Link>
          </Flex>
        </Container>
        <Carousel itemsToShow={[1, 2, 3]} endButton={events?.length === MAX_EVENTS}>
          {events?.map(event => (
            <LinkedEvent key={event.id} event={event} timeZone={event.venue.city.timezone} />
          ))}
          {events?.length === MAX_EVENTS && (
            <Link
              href="/[geo]/events/all/[...params]"
              as={`/${geo}/events/all/${categorySlug}`}
              channel={channel}
              aria-label="see all events"
              withFilters
            >
              <SeeAllButton text={t('common:seeAll')} />
            </Link>
          )}
        </Carousel>
      </Box>
    </>
  );
});

export default EventsCategoryPreview;

EventsCategoryPreview.propTypes = {
  /**
   * The title of the category section
   */
  title: PropTypes.string.isRequired,
  /**
   * The text below the title of the category
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * The category slug used for the API calls
   */
  categorySlug: PropTypes.string.isRequired,
  /**
   *  The array of event to display coming from the server when rendering for bots
   */
  // eslint-disable-next-line react/require-default-props
  initialData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Slug of the city
   */
  geo: PropTypes.string.isRequired,
  /**
   * Indicates if this category preview is displayed in a City Page in order to change the show/hidde logic
   */
  inCityPage: PropTypes.bool,
  /**
   * Callback function executed when the useQuery resolve the onSuccess event
   */
  onLoad: PropTypes.func,
};

EventsCategoryPreview.defaultProps = {
  inCityPage: false,
  onLoad: null,
};
