import React from 'react';
import { animated, Spring } from '@react-spring/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Container, Flex, FullWidthContainer } from '@xceedsrl/jukebox';

const AnimatedFlex = animated(Flex);
const AnimatedFullWidthContainer = animated(FullWidthContainer);

const BACKGROUNDS = {
  dark: 'background.snow',
  light: 'background.pureWhite',
  gloom: 'background.smokeWhite',
};

const MOBILE = 36;
const TABLET = 60;
const DESKTOP = 100;

export const POSITIVE_SPACE = [MOBILE, TABLET, DESKTOP];
export const NEGATIVE_SPACE = [-MOBILE, -TABLET, -DESKTOP];
export const SMALL_POSITIVE_SPACE = [MOBILE, TABLET];

const BasePageSection = React.forwardRef(function BasePageSection(
  { full, topLeft, topRight, bottomLeft, bottomRight, backgroundStyle, children, ...props },
  ref
) {
  return (
    <FullWidthContainer
      ref={ref}
      backgroundColor={BACKGROUNDS[backgroundStyle]}
      position="relative"
      marginTop={topLeft || topRight ? NEGATIVE_SPACE : 0}
      paddingTop={POSITIVE_SPACE}
      paddingBottom={POSITIVE_SPACE}
      borderTopLeftRadius={topLeft ? POSITIVE_SPACE : 0}
      borderTopRightRadius={topRight ? POSITIVE_SPACE : 0}
      borderBottomLeftRadius={bottomLeft ? POSITIVE_SPACE : 0}
      borderBottomRightRadius={bottomRight ? POSITIVE_SPACE : 0}
      {...props}
    >
      {full ? children : <Container>{children}</Container>}
    </FullWidthContainer>
  );
});

export const PageHeader = styled(BasePageSection)``;
export const PageContent = styled(BasePageSection)``;
export const PageHero = styled(BasePageSection)``;
export const PageFooter = styled(BasePageSection)``;

export function PageMedia(props) {
  return (
    <Spring native from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {style => (
        <AnimatedFlex
          style={style}
          position="relative"
          justifyContent="center"
          zIndex={1}
          display={['none', 'none', 'flex']}
        >
          <Box
            width={4 / 5}
            maxWidth={920}
            position="absolute"
            style={{
              transform: 'translate3d(0,calc(-100% - 50px), 0)',
            }}
            {...props}
          />
        </AnimatedFlex>
      )}
    </Spring>
  );
}

export function PageBottomBar(props) {
  return (
    <Spring
      native
      from={{ transform: 'translate3d(0,100%,0)' }}
      to={{ transform: 'translate3d(0,0,0)' }}
    >
      {style => (
        <AnimatedFullWidthContainer
          style={style}
          zIndex={1}
          bottom={0}
          position="fixed"
          boxShadow="0 -5px 36px 0 rgba(75, 80, 94, 0.06)"
          borderRadius="36px 36px 0 0"
          bg={BACKGROUNDS.light}
        >
          <Container
            height={72}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            {...props}
          />
        </AnimatedFullWidthContainer>
      )}
    </Spring>
  );
}

BasePageSection.propTypes = {
  /**
   * Sets background color
   */
  backgroundStyle: PropTypes.oneOf(['light', 'dark', 'gloom']),
  /**
   * If true, the children should have not a parent container
   */
  full: PropTypes.bool,
  /**
   * If true, it will have the topLeft border radius
   */
  topLeft: PropTypes.bool,
  /**
   * If true, it will have the topRight border radius
   */
  topRight: PropTypes.bool,
  /**
   * If true, it will have the bottomRight border radius
   */
  bottomRight: PropTypes.bool,
  /**
   * If true, it will have the bottomLeft border radius
   */
  bottomLeft: PropTypes.bool,
  /**
   * Section content
   */
  children: PropTypes.node.isRequired,
};

PageHero.propTypes = BasePageSection.propTypes;
PageHeader.propTypes = BasePageSection.propTypes;
PageContent.propTypes = BasePageSection.propTypes;
PageFooter.propTypes = BasePageSection.propTypes;

BasePageSection.defaultProps = {
  backgroundStyle: 'light',
  full: false,
  topLeft: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
};

PageContent.defaultProps = {
  backgroundStyle: 'dark',
  paddingTop: SMALL_POSITIVE_SPACE,
  paddingBottom: SMALL_POSITIVE_SPACE,
};

PageFooter.defaultProps = {
  full: true,
  marginTop: NEGATIVE_SPACE,
  paddingTop: 0,
  paddingBottom: 0,
};

PageHero.displayName = 'PageHero';
PageHeader.displayName = 'PageHeader';
PageContent.displayName = 'PageContent';
PageFooter.displayName = 'PageFooter';
