import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '@xceedsrl/jukebox';

const backgroundImages = [
  'xceed-events-clubs-djs-1.jpg',
  'xceed-events-clubs-djs-2.jpg',
  'xceed-events-clubs-djs-3.jpg',
  'xceed-events-clubs-djs-4.jpg',
  'xceed-events-clubs-djs-5.jpg',
  'xceed-events-clubs-djs-6.jpg',
  'xceed-events-clubs-djs-7.jpg',
  'xceed-events-clubs-djs-11.jpg',
  'xceed-events-clubs-djs-12.jpg',
];

const imagesSelection = (function selectRandomPictures() {
  const selection = [];
  while (selection.length < 5) {
    const pos = Math.floor(Math.random() * backgroundImages.length);
    const choosenPic = backgroundImages[pos];
    if (!selection.includes(choosenPic)) selection.push(choosenPic);
  }
  return selection;
})();

const DURATION = 5;

const aQuarter = 100 / imagesSelection.length;
const aEighth = aQuarter / 2;
const imageAnimation = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  ${aEighth}% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  ${aQuarter}% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  ${aQuarter + aEighth}% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const ImageList = styled(Box)`
  &&& {
    background: #000;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
`;

const ImageSlide = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 0;
  color: transparent;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('${p => p.src}');
  animation: ${imageAnimation} ${DURATION * imagesSelection.length}s linear infinite 0s;
  animation-delay: ${p => p.delay && `${p.delay}s`};
`;

function makeUrls(nameList) {
  const minHeight = window.innerHeight;
  const baseUrl = `https://images.xceed.me/static/b2cweb/home`;
  return nameList.map(name => `${baseUrl}/${name}?h=${minHeight}&fm=auto`);
}

function LandingBackground({ children, ...props }) {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const imgs = makeUrls(imagesSelection);
    setImages(imgs);
  }, []);

  return (
    <ImageList
      height={['calc(100vh + 36px)', 'calc(100vh + 60px)', 'calc(100vh + 100px)']}
      {...props}
    >
      {images.map((src, i) => (
        <ImageSlide key={i} src={src} delay={i * DURATION} />
      ))}
      {children}
    </ImageList>
  );
}

export default LandingBackground;
