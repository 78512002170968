const defaultNews = {
  id: null,
  publishedAt: null,
  title: null,
  subtitle: null,
  url: null,
  imageUrl: null,
  locale: null,
  externalId: null,
};

export class News {
  constructor(news = defaultNews) {
    Object.assign(this, { ...defaultNews, ...news });
  }
}
