import React, { forwardRef } from 'react';
import * as Types from 'types';

import { SimpleCard } from '@xceedsrl/jukebox';

import Link from 'components/CustomLink';

export const CityWrapper = forwardRef(function CityWrapper({ city, ...props }, ref) {
  return (
    <SimpleCard
      ref={ref}
      coverUrl={city.coverUrl}
      title={city.name}
      {...(city?.country?.name && { subtitle: city.country.name })}
      {...props}
    />
  );
});

export const LinkedCity = forwardRef(function LinkedCityWrapper(props, ref) {
  const {
    city: { slug, name },
  } = props;

  return (
    <Link href="/[geo]" as={`/${slug}`} aria-label={name}>
      <CityWrapper ref={ref} {...props} />
    </Link>
  );
});

CityWrapper.propTypes = {
  city: Types.CitySimple.isRequired,
};

LinkedCity.propTypes = CityWrapper.propTypes;
