import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import * as Types from 'types';

import { ActionBar, EventCard, EventCardLarge, useMediaQuery } from '@xceedsrl/jukebox';

import { useLineup } from 'hooks/useLineup';
import { useTranslation } from 'i18n/';
import { useAudio } from 'store/audio';
import { useChannelState } from 'store/channel';

import Link from 'components/CustomLink';

import { LikeAction } from './LikeAction';

export const EventWrapper = forwardRef(function EventWrapper(
  { event, likeDisabled, timeZone, showIconMapLocation, isPass, fullImageCentered, ...props },
  outerRef
) {
  const isMobile = useMediaQuery('mobile');
  const { lang } = useTranslation();
  const { audioId, stopAudio, toggleAudio } = useAudio();
  const [ref, inView] = useInView({ rootMargin: '300px' });

  const {
    id,
    coverUrl,
    name,
    startingTime,
    endingTime,
    venue: { name: venueName },
  } = event;

  const isPlaying = id === audioId;

  const { lineup: lineUpData, refetch } = useLineup({ id, enabled: false });

  useImperativeHandle(outerRef, () => ref.current);

  useEffect(() => {
    if (!isPass && inView && !lineUpData) refetch();
  }, [isPass, inView, lineUpData]);

  const actionElement = useMemo(() => {
    const lineUp = lineUpData?.length && lineUpData[0].tracks;
    return (
      <ActionBar gap={1}>
        {likeDisabled ? null : <LikeAction type="events" id={id} name={name} />}
        {!!lineUp?.length && (
          <ActionBar.Music
            width={isMobile ? '22.5px' : '38px'}
            height={isMobile ? '24px' : '40px'}
            data-id={id}
            tracks={lineUp}
            isPlaying={isPlaying}
            onClick={toggleAudio}
            onEnd={stopAudio}
          />
        )}
      </ActionBar>
    );
  }, [isPlaying, lineUpData, isMobile]);

  return (
    <EventCard
      ref={ref}
      coverUrl={coverUrl}
      name={name}
      startingTime={startingTime}
      endingTime={endingTime}
      location={venueName}
      lang={lang}
      timeZone={timeZone}
      actionElement={actionElement}
      showIconMapLocation={showIconMapLocation}
      fullImageCentered={fullImageCentered}
      {...props}
    />
  );
});

export const EventLargeWrapper = forwardRef(function EventLargeWrapper(
  { event, showVenueName, ...props },
  outerRef
) {
  const isMobile = useMediaQuery('mobile');
  const { lang } = useTranslation();
  const { audioId, stopAudio, toggleAudio } = useAudio();
  const [ref, inView] = useInView({ rootMargin: '300px' });

  const { id, coverUrl, name, startingTime, venue } = event;

  const isPlaying = id === audioId;

  const { lineup: lineUpData, refetch } = useLineup({ id, enabled: false });

  useImperativeHandle(outerRef, () => ref.current);

  useEffect(() => {
    if (inView && !lineUpData) refetch();
  }, [inView, lineUpData]);

  const actionElement = useMemo(() => {
    const lineUp = lineUpData?.length && lineUpData[0].tracks;
    return (
      <ActionBar gap={0} justifyContent="center" flexWrap="wrap" width="min-content">
        {!!lineUp?.length && (
          <ActionBar.Music
            width={isMobile ? '22.5px' : '38px'}
            height={isMobile ? '24px' : '40px'}
            data-id={id}
            tracks={lineUp}
            isPlaying={isPlaying}
            onClick={toggleAudio}
            onEnd={stopAudio}
            paddingX={0}
            mb={1}
          />
        )}
      </ActionBar>
    );
  }, [isPlaying, lineUpData, isMobile]);

  return (
    <EventCardLarge
      ref={ref}
      name={name}
      coverUrl={coverUrl}
      startingTime={startingTime}
      lang={lang}
      lineUp={lineUpData}
      actionElement={actionElement}
      venue={showVenueName ? venue.name : ''}
      {...props}
    />
  );
});

const LinkedWrapper = forwardRef(function LinkedEventWrapper(
  { card: Card, customChannel, ...props },
  ref
) {
  const {
    event: {
      legacyId,
      slug,
      name,
      venue: { city },
    },
  } = props;

  const channel = useChannelState();

  return (
    <Link
      href="/[geo]/event/[...params]"
      as={`/${city.slug}/event/${slug}--${legacyId}`}
      channel={customChannel || channel}
      aria-label={name}
    >
      <Card ref={ref} {...props} />
    </Link>
  );
});

export const LinkedEvent = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={EventWrapper} {...props} />
));

export const LinkedEventLarge = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={EventLargeWrapper} {...props} />
));

EventWrapper.propTypes = {
  event: Types.Event.isRequired,
  showVenueName: PropTypes.bool,
  likeDisabled: PropTypes.bool,
  showIconMapLocation: PropTypes.bool,
  fullImageCentered: PropTypes.bool,
};

EventWrapper.defaultProps = {
  likeDisabled: false,
  showIconMapLocation: true,
  fullImageCentered: true,
};

EventLargeWrapper.propTypes = EventWrapper.propTypes;
EventWrapper.defaultProps = {
  showVenueName: false,
};

LinkedWrapper.propTypes = {
  card: PropTypes.oneOf([EventWrapper, EventLargeWrapper]).isRequired,
  customChannel: PropTypes.string,
  ...EventWrapper.propTypes,
};

LinkedWrapper.defaultProps = {
  customChannel: null,
  ...EventWrapper.defaultProps,
};

LinkedEvent.propTypes = EventWrapper.propTypes;
LinkedEventLarge.propTypes = EventWrapper.propTypes;
