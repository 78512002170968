const countries = new Set([
  'afghanistan',
  'albania',
  'antarctica',
  'algeria',
  'american-samoa',
  'andorra',
  'angola',
  'antigua-and-barbuda',
  'azerbaijan',
  'argentina',
  'australia',
  'austria',
  'bahamas',
  'bahrain',
  'bangladesh',
  'armenia',
  'barbados',
  'belgium',
  'bermuda',
  'bhutan',
  'bolivia',
  'bosnia-and-herzegovina',
  'botswana',
  'bouvet-island',
  'brazil',
  'belize',
  'british-indian-ocean-territory',
  'solomon-islands',
  'virgin-islands-british',
  'brunei-darussalam',
  'bulgaria',
  'myanmar',
  'burundi',
  'belarus',
  'cambodia',
  'cameroon',
  'canada',
  'cape-verde',
  'cayman-islands',
  'central-african-republic',
  'sri-lanka',
  'chad',
  'chile',
  'china',
  'taiwan',
  'christmas-island',
  'cocos-keeling-islands',
  'colombia',
  'comoros',
  'mayotte',
  'congo',
  'democratic-republic-of-congo',
  'cook-islands',
  'costa-rica',
  'croatia-hrvatska',
  'cuba',
  'cyprus',
  'czech-republic',
  'benin',
  'denmark',
  'dominica',
  'dominican-republic',
  'ecuador',
  'el-salvador',
  'equatorial-guinea',
  'ethiopia',
  'eritrea',
  'estonia',
  'faroe-islands',
  'falkland-islands-malvinas',
  'south-georgia-south-sandwich-islands',
  'fiji',
  'finland',
  'islas-gland',
  'france',
  'french-guiana',
  'french-polynesia',
  'french-southern-territories',
  'djibouti',
  'gabon',
  'georgia',
  'gambia',
  'palestine',
  'germany',
  'ghana',
  'gibraltar',
  'kiribati',
  'greece',
  'greenland',
  'grenada',
  'guadeloupe',
  'guam',
  'guatemala',
  'guinea',
  'guyana',
  'haiti',
  'heard-and-mc-donald-islands',
  'vatican-city-state',
  'honduras',
  'hong-kong',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iran-islamic-republic-of',
  'iraq',
  'ireland',
  'israel',
  'italy',
  'ivory-coast',
  'jamaica',
  'japan',
  'kazakhstan',
  'jordan',
  'kenya',
  'korea-democratic-peoples-republic-of',
  'korea-republic-of',
  'kuwait',
  'kyrgyzstan',
  'lao-peoples-democratic-republic',
  'lebanon',
  'lesotho',
  'latvia',
  'liberia',
  'libyan-arab-jamahiriya',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macau',
  'madagascar',
  'malawi',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'martinique',
  'mauritania',
  'mauritius',
  'mexico',
  'monaco',
  'mongolia',
  'moldova-republic-of',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'oman',
  'namibia',
  'nauru',
  'nepal',
  'netherlands',
  'netherlands-antilles',
  'aruba',
  'new-caledonia',
  'vanuatu',
  'new-zealand',
  'nicaragua',
  'niger',
  'nigeria',
  'niue',
  'norfolk-island',
  'norway',
  'northern-mariana-islands',
  'united-states-minor-outlying-islands',
  'micronesia-federated-states-of',
  'marshall-islands',
  'palau',
  'pakistan',
  'panama',
  'papua-new-guinea',
  'paraguay',
  'peru',
  'philippines',
  'pitcairn',
  'poland',
  'portugal',
  'guinea-bissau',
  'timor-leste',
  'puerto-rico',
  'qatar',
  'reunion',
  'romania',
  'russian-federation',
  'rwanda',
  'st-helena',
  'saint-kitts-and-nevis',
  'anguilla',
  'saint-lucia',
  'st-pierre-and-miquelon',
  'saint-vincent-and-the-grenadines',
  'san-marino',
  'sao-tome-and-principe',
  'saudi-arabia',
  'senegal',
  'serbia',
  'seychelles',
  'sierra-leone',
  'singapore',
  'slovakia',
  'vietnam',
  'slovenia',
  'somalia',
  'south-africa',
  'zimbabwe',
  'spain',
  'western-sahara',
  'sudan',
  'suriname',
  'svalbard-and-jan-mayen-islands',
  'swaziland',
  'sweden',
  'switzerland',
  'syrian-arab-republic',
  'tajikistan',
  'thailand',
  'togo',
  'tokelau',
  'tonga',
  'trinidad-and-tobago',
  'united-arab-emirates',
  'tunisia',
  'turkey',
  'turkmenistan',
  'turks-and-caicos-islands',
  'tuvalu',
  'uganda',
  'ukraine',
  'macedonia',
  'egypt',
  'united-kingdom',
  'tanzania-united-republic-of',
  'united-states',
  'virgin-islands-us',
  'burkina-faso',
  'uruguay',
  'uzbekistan',
  'venezuela',
  'wallis-and-futuna-islands',
  'samoa',
  'yemen',
  'zambia',
  'demo-country,',
]);

export function getGeoType(geo) {
  if (geo === 'world') return 'world';
  if (countries.has(geo)) return 'country';
  return 'city';
}

export function getGeoQueryParams(geo) {
  if (!geo || geo === 'world') return null;
  if (countries.has(geo)) return { countries: geo };
  return { cities: geo };
}
