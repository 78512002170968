/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import {
  Box,
  Carousel,
  Col,
  Container,
  Flex,
  NewsCard,
  Row,
  SecondaryText,
  SecondaryTitle,
  SkeletonNewsCard,
  Text,
  useMediaQuery,
} from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n';
import { NewsController } from 'services/apiClient/interfaces/NewsController';
import { useStaticData } from 'store/staticData';

import Link from 'components/CustomLink';

import { SkeletonHeader } from './components';

const NewsCategoryPreview = React.memo(function NewsCategoryPreview(props) {
  const { title, subtitle, initialData, entityIds, navItem, ...rest } = props;
  const { t, lang } = useTranslation();
  const isMobile = useMediaQuery('mobile');
  const { isFromBot } = useStaticData();

  const {
    data: news,
    error,
    status,
  } = useQuery(['news', { lang, entityIds }], () => NewsController.getNews({ entityIds, lang }), {
    initialData,
    // This prevents the query to re-run when rendering for bots.
    // All the information is provided by initialEvents coming from getInitialProps
    enabled: !isFromBot,
  });
  if (status === 'success' && !news?.length) return null;
  if (status === 'loading')
    return (
      <Container mb={5}>
        <Row>
          <Col width={[1, 1 / 3]}>
            <SkeletonHeader uniqueKey="news-header" />
          </Col>
        </Row>
        <Row>
          {Array(isMobile ? 1 : 3)
            .fill(SkeletonNewsCard)
            .map((Skeleton, i) => (
              <Col width={[1, 1 / 3]} key={`news-${i}`}>
                <Skeleton uniqueKey={`news-${i}`} />
              </Col>
            ))}
        </Row>
      </Container>
    );
  return (
    <Box mb={2} {...rest}>
      <Container>
        <Flex mb={2} flexDirection="column">
          <SecondaryTitle ref={navItem?.getMeasure} mb={1}>
            {title}
          </SecondaryTitle>
          {subtitle && <SecondaryText>{subtitle}</SecondaryText>}
        </Flex>
      </Container>
      <Carousel itemsToShow={[1, 2, 3]}>
        {news?.map(article => (
          <Link key={article.id} href={article.url} aria-label={article.title}>
            <NewsCard
              coverUrl={article.imageUrl}
              title={article.title}
              subtitle={article.subtitle}
            />
          </Link>
        ))}
      </Carousel>
      {error && (
        <Container>
          <Text>{t('common:fetchError', { msg: error })}</Text>
        </Container>
      )}
    </Box>
  );
});

export default NewsCategoryPreview;

NewsCategoryPreview.propTypes = {
  /**
   * The title of the category section
   */
  title: PropTypes.string.isRequired,
  /**
   * The text below the title of the category
   */
  subtitle: PropTypes.string,
  /**
   *  The array of venues to display coming from the server when rendering for bots
   */
  // eslint-disable-next-line react/require-default-props
  initialData: PropTypes.arrayOf(PropTypes.object),
  /**
   *  The filters object parsed as query strings to fetch events
   */
  filters: PropTypes.shape({
    musicGenres: PropTypes.string,
    vibes: PropTypes.string,
    venues: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }),
  /**
   * Object with the Ids of each entity accepted by the NewsController (single string or Array of strings)
   */
  entityIds: PropTypes.shape({
    artists: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    venues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    cities: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    countries: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    tag: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
};

NewsCategoryPreview.defaultProps = {
  subtitle: null,
  filters: {
    musicGenres: '',
    vibes: '',
    venues: '',
    startTime: NaN,
    endTime: NaN,
  },
  entityIds: null,
};
