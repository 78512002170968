import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as Types from 'types';

import { ActionBar, composeRef, VenueCard, VenueCardInMap } from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';
import { ClubController } from 'services/apiClient/interfaces/ClubController';
import { useChannelState } from 'store/channel';

import Link from 'components/CustomLink';

import { LikeAction } from './LikeAction';

export const ClubWrapper = forwardRef(function ClubWrapper({ club, likeDisabled, ...props }, ref) {
  const { id, coverUrl, name } = club;

  const actionElement = useMemo(() => {
    return (
      <ActionBar gap={1}>
        {likeDisabled ? null : <LikeAction type="clubs" id={id} name={name} />}
      </ActionBar>
    );
  }, [likeDisabled, id, name]);

  return (
    <VenueCard ref={ref} coverUrl={coverUrl} name={name} actionElement={actionElement} {...props} />
  );
});

export const ClubSmallWrapper = forwardRef(function ClubSmallWrapper({ club, ...props }, outerRef) {
  const { lang, t } = useTranslation();
  const [ref, inView] = useInView({
    rootMargin: '400px',
  });

  const { data: clubDetails, refetch } = useQuery(
    ['club', { id: club.id, lang }],
    () => ClubController.getInfo({ id: club.id, lang }),
    { enabled: false }
  );

  const setRefs = useCallback(composeRef(ref, outerRef), [ref]);

  useEffect(() => {
    if (inView && !clubDetails) refetch();
  }, [inView, clubDetails]);

  return (
    <VenueCardInMap
      ref={setRefs}
      name={club.name}
      coverUrl={club.coverUrl}
      type={t('common:club')}
      priceLevel={clubDetails?.priceLevel}
      tags={clubDetails ? [...clubDetails.facilities, ...clubDetails.characteristics] : undefined}
      {...props}
    />
  );
});

const LinkedWrapper = forwardRef(function LinkedClubWrapper(
  { card: Card, customChannel, openInNewTab, ...props },
  ref
) {
  const {
    club: { slug, name, city },
  } = props;

  const channel = useChannelState();

  return (
    <Link
      href="/[geo]/club/[...params]"
      as={`/${city.slug}/club/${slug}`}
      channel={customChannel || channel}
      aria-label={name}
      openInNewTab={openInNewTab}
    >
      <Card ref={ref} {...props} />
    </Link>
  );
});

export const LinkedClub = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={ClubWrapper} {...props} />
));

export const LinkedClubSmall = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={ClubSmallWrapper} {...props} />
));

ClubWrapper.propTypes = {
  club: Types.Club.isRequired,
  likeDisabled: PropTypes.bool,
};

ClubWrapper.defaultProps = {
  likeDisabled: false,
};

ClubSmallWrapper.propTypes = {
  club: Types.Club.isRequired,
};

LinkedWrapper.propTypes = {
  card: PropTypes.oneOf([ClubWrapper, ClubSmallWrapper]).isRequired,
  customChannel: PropTypes.string,
  openInNewTab: PropTypes.bool,
  ...ClubWrapper.propTypes,
};

LinkedWrapper.defaultProps = {
  customChannel: null,
  openInNewTab: false,
};

LinkedClub.propTypes = ClubWrapper.propTypes;

LinkedClubSmall.propTypes = ClubSmallWrapper.propTypes;
