const defaultCategory = {
  slug: null,
  name: null,
  subtitle: null,
  meta: {
    title: null,
    description: null,
  },
  type: null,
  content: [],
  contentLength: null,
};

export class Category {
  constructor(category = defaultCategory) {
    Object.assign(this, Category.parse({ ...defaultCategory, ...category }));
  }

  static parse(category) {
    const { content } = category;
    let contentLength;
    if (Array.isArray(content)) {
      contentLength = content.length;
    }
    return { ...category, contentLength };
  }
}
