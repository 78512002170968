import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useScroll } from 'react-use-gesture';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';

import {
  Box,
  Carousel,
  ChevronDown,
  Container,
  Flex,
  FullWidthContainer,
  Icon,
  Search,
  SecondaryTitle,
  SuperTitle,
  Text,
  useMediaQuery,
} from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n';
import { CategoryController } from 'services/apiClient/interfaces/CategoryController';
import GoogleTagManager from 'services/GoogleTagManager';
import SEOGenerator from 'services/SEOGenerator';
import { useChannelLink } from 'store/channel';
import { useCities } from 'store/cities';
import { useSharedDataActions, useSharedDataState } from 'store/sharedData';

import ArtistsCategoryPreview from 'components/CategoryPreview/ArtistsCategoryPreview';
import BarsCategoryPreview from 'components/CategoryPreview/BarsCategoryPreview';
import ClubsCategoryPreview from 'components/CategoryPreview/ClubsCategoryPreview';
import EventsCategoryPreview from 'components/CategoryPreview/EventsCategoryPreview';
import NewsCategoryPreview from 'components/CategoryPreview/NewsCategoryPreview';
import RestaurantsCategoryPreview from 'components/CategoryPreview/RestaurantsCategoryPreview';
import { LinkedCity } from 'components/CityWrappers';
import LandingBackground from 'components/LandingBackground';
import { PageContent } from 'components/PageTemplate';
import { TopAnchor } from 'components/RefAnchors';
import CommonSeo from 'components/SEO/CommonSeo';

const AnimatedBox = animated(Box);
const SearchArea = styled(Flex)`
  cursor: pointer;
`;

const HOME_COVER = 'https://images.xceed.me/static/b2cweb/home/xceed-events-clubs-djs-6.jpg';

const CATEGORIES_PREVIEWS = {
  event: EventsCategoryPreview,
  bar: BarsCategoryPreview,
  restaurant: RestaurantsCategoryPreview,
  venue: ClubsCategoryPreview,
  artist: ArtistsCategoryPreview,
};

const Index = () => {
  // Essential top hooks calls
  const [mainRef, setMain] = useState();
  useChannelLink(false);
  const { navbar } = useSharedDataState();
  const { t, lang } = useTranslation();
  const [{ popularCities }] = useCities();
  const { openSearch, setNavbarTheme } = useSharedDataActions();

  // Media queries
  const isMobile = useMediaQuery('mobile');

  // Component internal state
  const contentRef = useRef();
  const mainHeight = useMemo(() => mainRef?.getBoundingClientRect().height, [mainRef]);
  const [fadeAnimation, set] = useSpring(() => ({ opacity: 1 }));

  // Data fetching queries
  const { data: categories } = useQuery(
    ['categories', { geo: 'world', lang }],
    () => CategoryController.getAll({ geo: 'world', lang }),
    { staleTime: Infinity }
  );

  // Custom hooks calls for additional logic
  const bind = useScroll(
    // eslint-disable-next-line no-unused-vars
    ({ xy: [_, y] }) => {
      const contentThreshold = isMobile ? 100 : 300;
      set({ opacity: y > contentThreshold ? 0 : 1 });
      const navbarThreshold = mainHeight - (isMobile ? 60 : 72);
      if (y > navbarThreshold) {
        setNavbarTheme('light');
      } else if (y < navbarThreshold && navbar.theme) {
        setNavbarTheme(undefined);
      }
    },
    {
      domTarget: typeof window !== 'undefined' && window,
      axis: 'y',
    }
  );

  // Side effects
  useEffect(bind, [bind]);
  useEffect(() => void setNavbarTheme(undefined), []);

  // Additional Event Handlers
  const handleSearch = useCallback(
    e => {
      e.stopPropagation();
      GoogleTagManager.searchBody();
      openSearch();
    },
    [openSearch]
  );

  const scrollToContent = useCallback(e => {
    e.stopPropagation();
    contentRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Content data
  const content = useMemo(
    () => ({
      motto: t('home:motto'),
      seoTitle: t('home:seoTitle'),
      searchHelper: t('home:searchHelper'),
      seoDescription: t('home:seoDescription'),
      popularDestinations: t('common:popularDestinations'),
      news: t('common:news'),
    }),
    [lang]
  );

  const seoContent = useMemo(() => {
    const metaDescription = content.seoDescription;
    const schema = new SEOGenerator({ lang }).makeWebsiteJson({ metaDescription });
    return {
      metaTitle: content.seoTitle,
      metaDescription,
      schema,
    };
  }, [lang]);

  return (
    <FullWidthContainer>
      <CommonSeo
        {...seoContent}
        pageTitle={seoContent.metaTitle}
        pageDescription={seoContent.metaDescription}
        metaImage={HOME_COVER}
      />
      <LandingBackground />
      <SearchArea
        ref={setMain}
        height={[
          'calc(100vh - 60px + 36px)',
          'calc(100vh - 72px + 60px)',
          'calc(100vh - 72px + 100px)',
        ]}
        onClick={handleSearch}
        flex={1}
        alignItems="center"
        justifyContent={['center', 'flex-start']}
        paddingX={[3, 150, 200]}
        paddingBottom={[150, 6]}
        position="relative"
      >
        <AnimatedBox style={fadeAnimation}>
          <SuperTitle
            color="brandLight"
            fontStyle="oblique"
            fontSize={72}
            lineHeight="72px"
            textStyle="caps"
            textAlign={['center', 'left']}
            userSelect="none"
            mb={[4, 3]}
            paddingX={[7, 0, 0]}
          >
            {content.motto}
          </SuperTitle>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent={['center', 'flex-start']}
            alignItems={['center', 'flex-start']}
            marginLeft={[0, 1]}
          >
            <Icon mr={[0, 2]} my={[1, 'auto']} color="background.mercury">
              <Search />
            </Icon>
            <Text textAlign={['center', 'left']} color="brandLight" mt="2px">
              {content.searchHelper}
            </Text>
          </Flex>
        </AnimatedBox>
        <Container
          position="absolute"
          left={0}
          bottom={[60, 84, 124]}
          display="flex"
          justifyContent={['center', 'flex-start']}
        >
          <Icon padding={1} color="background.mercury" onClick={scrollToContent}>
            <ChevronDown />
          </Icon>
        </Container>
      </SearchArea>
      <PageContent backgroundStyle="light" full>
        <TopAnchor position="absolute" ref={contentRef} />
        <NewsCategoryPreview title={content.news} entityIds={{ tag: 'world' }} />
        <Box mb={2}>
          <Container mb={3}>
            <SecondaryTitle>{content.popularDestinations}</SecondaryTitle>
          </Container>
          <Carousel itemsToShow={[2, 3, 5]}>
            {popularCities.map(city => (
              <LinkedCity key={city.id} city={city} />
            ))}
          </Carousel>
        </Box>
        {categories?.map(category => {
          const Preview = CATEGORIES_PREVIEWS[category.type];
          if (!Preview) return null;
          return (
            <Preview
              key={category.slug}
              title={category.name}
              subtitle={category.subtitle}
              categorySlug={category.slug}
              geo="world"
            />
          );
        })}
      </PageContent>
    </FullWidthContainer>
  );
};

export default Index;
